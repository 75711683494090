<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" md="2">
        <Select
          v-model="search.is_attend"
          @on-change="SearchData"
          :clearable="true"
          placeholder="Select Attendance"
        >
          <Option :value="1"> Attend</Option>
          <Option :value="2"> Not Attend</Option>
        </Select>
      </b-col>
      <b-col cols="12" md="2">
        <Select
          v-model="search.is_cancel"
          @on-change="SearchData"
          :clearable="true"
          placeholder="Meeting status"
        >
          <Option :value="1"> Active</Option>
          <Option :value="2"> Canceled</Option>
        </Select>
      </b-col>
      <b-col cols="12" md="2">
        <Select
          v-model="search.select_status"
          @on-change="SearchData"
          :clearable="true"
          placeholder="Candidate status"
        >
          <Option :value="1"> Pending</Option>
          <Option :value="2"> Short listed</Option>
          <Option :value="3"> Interviewing</Option>
          <Option :value="4"> Selected</Option>
          <Option :value="5"> Rejected</Option>
          <Option :value="6"> Cancel interview</Option>
        </Select>
      </b-col>
      <b-col cols="12" md="2">
        <Select
          v-model="search.schedule_status"
          @on-change="SearchData"
          :clearable="true"
          placeholder="Meeting schedule"
        >
          <Option :value="1"> Today</Option>
          <Option :value="2"> Past</Option>
          <Option :value="3"> Upcoming</Option>
        </Select>
      </b-col>
      <b-col cols="12" md="4" class="mb-1 mb-md-0 text-right">
        <label class="mr-1">Show</label>
        <Select
          style="width: 60px"
          @on-change="paginateChange"
          v-model="search.paginate"
          :clearable="false"
        >
          <Option :value="10"> 10</Option>
          <Option :value="30"> 30</Option>
          <Option :value="50"> 50</Option>
          <Option :value="80"> 80</Option>
          <Option :value="100"> 100</Option>
        </Select>
      </b-col>
    </b-row>
    <b-card-text>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th class="text-center">Sl</th>
              <th>Company</th>
              <th>Job details</th>
              <th>Schedule Summary</th>
              <th>Schedule</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="schedules.data.length"
              v-for="(schedule, index) in schedules.data"
              :key="index"
            >
              <td class="align-middle text-center">
                {{ schedules.meta.from }}
              </td>
              <td>
                <img :src="schedule.company_logo" height="70" alt="dd" />
                <p>
                  <b>{{ schedule.company_name }}</b>
                </p>
              </td>

              <td>
                <p>
                  <b>{{ schedule.company_job_title }}</b>
                </p>
                <p><b>Commitment: </b>{{ schedule.company_job_commitment }}</p>
                <p>
                  <b>Workplace: </b>{{ schedule.company_job_workplace_type }}
                </p>
              </td>
              <td>
                <p><b>Summary: </b>{{ schedule.summary }}</p>
                <p><b>Location: </b>{{ schedule.location }}</p>
                <p><b>Description: </b>{{ schedule.description }}</p>
              </td>
              <td>
                <p><b>Start time: </b>{{ schedule.start_time }}</p>
                <p><b>End time: </b>{{ schedule.end_time }}</p>
                <p><b>Meeting link: </b>{{ schedule.meet_link }}</p>
              </td>
              <td>
                <p>
                  <b>Interview: </b>
                  <span
                    :class="
                      schedule.is_cancel === 'Interview Cancel'
                        ? 'badge badge-danger'
                        : 'badge badge-success'
                    "
                  >
                    {{
                      schedule.is_cancel === "Interview Cancel"
                        ? "Canceled"
                        : "Active"
                    }}
                  </span>
                </p>
                <p><b>Attendance: </b>{{ schedule.is_attend }}</p>
                <p>
                  <b>Status: </b>
                  <span
                    :class="
                      badgeClass(schedule.proposed_candidate_select_status)
                    "
                    >{{ schedule.proposed_candidate_select_status }}</span
                  >
                </p>
              </td>
            </tr>

            <tr v-if="!schedules.data.length">
              <td class="text-center" colspan="6">
                <h4>No data found!</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-muted"
            >Showing {{ schedules.meta.from }} to {{ schedules.meta.to }} of
            {{ schedules.meta.total }} entries</span
          >
          <pagination
            :data="schedules.meta"
            @pagination-change-page="getResults"
            align="right"
            :show-disabled="true"
            :limit="5"
          ></pagination>
        </div>
      </b-card-body>
    </b-card-text>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        status: true,
      }),
      search: {
        search_data: "",
        status: "",
        is_attend: "",
        is_cancel: "",
        select_status: "",
        schedule_status: "",
        company_id: this.$route.query.company_id || "",
        company_job_id: this.$route.query.company_job_id || "",
        candidate_id: this.$route.query.candidate_id || "",
        paginate: 10,
        page: 1,
      },
      schedules: {},
    };
  },
  components: {
    BCardBody,
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/candidate/interview-schedule${queryParams}`)
        .then((res) => {
          this.schedules = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    badgeClass(status) {
      return {
        "badge badge-primary": status === "Pending",
        "badge badge-info": status === "Short listed",
        "badge badge-warning": status === "Interviewing",
        "badge badge-success": status === "Selected",
        "badge badge-danger": status === "Rejected",
        "badge badge-secondary": status === "Cancel interview",
      };
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },

  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
</style>
